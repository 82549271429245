import service from "./axios";
// import { postByUrlencoded } from "./common";

// 获取验证码
export function getYzm(){
    return service({
        url: "/fSys/vimg",
        method: "get",
        responseType: "arraybuffer",
    });
}

// 登录
export function login(params) {
    return service({
        url: "/fSys/login ",
        method: "post",
        data:params,
    });
}

// 退出登录
export function logOut() {
    return service({
        url:"/fSys/logout ",
        method:"post",
    })
}