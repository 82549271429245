<template>
    <div class="height-all-100">
        <div class="height-36 margin-bottom-20 flex align-items-center justify-content-space-between ">
            <div class="font-size-18 font-weight-600">模型数据详情</div>
            <el-button type="primary" plain @click="$router.go(-1)">退出详情</el-button>
        </div>
        <div>
            <el-card class="box-card margin-bottom-10 height-240">
                <div class="color-27254c font-size-24 font-weight-500 flex align-items-center margin-bottom-10">模型名称：
                    <span class="color-9a9cb6 font-weight-500">{{ detailsDate.modelName }}</span>
                    <div class="margin-left-40 color-27254c font-size-18 font-weight-500">框架名称：
                        <span class="color-9a9cb6 font-weight-500">{{ detailsDate.frameName }}</span>
                    </div>
                </div>
                <div class="color-27254c font-weight-500 font-size-18 height-80 margin-bottom-10">模型描述：<span
                        class="color-9a9cb6">{{ detailsDate.modelDesc }}</span></div>
                <div class="color-27254c font-weight-500 font-size-14 margin-bottom-10"
                    @click="download(detailsDate.modelFile)">模型文件：<span
                        class="color-409eff cursor-pointer">{{ stringInterception(detailsDate.modelFile, 37) }}</span>
                </div>
                <div class="font-size-14 flex justify-content-space-between">
                    <div class="color-27254c font-size-14">创建时间：
                        <span class="color-9a9cb6">{{ detailsDate.createTime }}</span>
                    </div>
                    <div class="color-27254c font-size-14">模型训练轮数：<span
                            class="color-9a9cb6">{{ detailsDate.trainNum }}</span></div>
                    <div class="color-27254c font-size-14">mAP50：<span
                            class="color-9a9cb6">{{ detailsDate.map50 }}</span></div>
                    <div class="color-27254c font-size-14">map5095：<span
                            class="color-9a9cb6">{{ detailsDate.map5095 }}</span></div>
                    <div class="color-27254c font-size-14">召回率：<span
                            class="color-9a9cb6">{{ detailsDate.recall }}</span></div>
                    <div class="color-27254c font-size-14">准确率：<span
                            class="color-9a9cb6">{{ detailsDate.accuracy }}</span></div>
                </div>
            </el-card>
            <el-card class="box-card height-535">
                <div class="color-27254c font-size-24 font-weight-500 margin-bottom-10">数据集名称：
                    <span class="color-9a9cb6 font-weight-500">{{ detailsDate.datasourceName }}</span>
                </div>
                <div class="color-27254c font-weight-500 font-size-18 height-80 margin-bottom-10">数据集描述：
                    <span class="color-9a9cb6">{{ detailsDate.datasourceDesc }}</span>
                </div>
                <div class="color-27254c font-weight-500 font-size-14 margin-bottom-10" @click="download(detailsDate.datasource)">数据集合：
                    <span
                        class="color-409eff cursor-pointer">{{ stringInterception(detailsDate.datasource, 37) }}</span>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
import * as moodelDataApi from "@/request/modelData";
export default {
    name: '',
    data() {
        return {
            detailsDate: "",
        };
    },

    components: {},

    computed: {},

    mounted() {
        this.getModelDetails();
    },
    methods: {
        stringInterception(item, index) {
            if (item === undefined) return;
            const data = item.slice(index);
            return data;
        },
        // 模型详情
        getModelDetails() {
            const id = this.$route.query.id;
            moodelDataApi.ModelDetails(id).then((res) => {
                if (res.code === "0") {
                    this.detailsDate = res.data;
                }
            }).catch(() => { });
        },
        // 下载
        download(file) {
            moodelDataApi.ModelDownload(this.stringInterception(file)).then(res => {
                console.log('[ res ] >', res);
                const link = document.createElement("a");
                link.setAttribute("style", "display:none");
                link.href = window.URL.createObjectURL(new Blob([res]));
                link.download = this.stringInterception(file,37); // 文件名称也可以从服务端返回
                link.target = "_blank";
                console.log('[ link ] >', link)
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
        }
    }
}

</script>
<style lang='scss' scoped></style>