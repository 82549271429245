// 引入axios,直接和后台接口打交道，统一处理所有接口(加token)

import axios from "axios";
import vue from "../main.js";

  const service = axios.create({
    // 这里的/api和vue.config.js中的target中的服务器地址是一一对应的
    baseURL: "/webapi",
    // 请求超时时间
    timeout: 5000,
  });

  // 请求拦截
  axios.interceptors.request.use(
    (config) => {
      return config;
    },
    (error)=> {
      return Promise.reject(error);
    }
  );

  // 响应拦截
  service.interceptors.response.use(
    (response) => {
      let res = response.data;
      // 要根据后端定义的code值做相应操作
      if (res.code == 400) {
        vue.$router.push("/login");
      }
      return Promise.resolve(res);
    },
    (error) => {
      return Promise.reject(error);
    }
  );

// 默认导出service
export default service;
