<template>
    <div>
        <h3 class="headline">模型管理系统</h3>
        <div class="main">
            <div class="loginModule">
                <div class="subheading">Loing</div>
                <div class="title">用户登录</div>
                <el-form :model="Form" :rules="rules" ref="Form"
                    :hide-required-asterisk="true">
                    <el-form-item label="用户名/手机号" prop="uname">
                        <el-input v-model="Form.uname" placeholder="请输入用户名/手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="pwd">
                        <el-input type="password" v-model="Form.pwd" placeholder="请输入密码"></el-input>
                    </el-form-item>
                    <el-form-item label="验证码" prop="code">
                        <div class="flex width-all-100">
                            <el-input class="width-120" v-model="Form.code" placeholder="请输入验证码"></el-input>
                            <el-image style="width:100px;height:40px" :src="url" lazy></el-image>
                            <div class="font-size-10 color-409eff cursor-pointer" @click="VerificationPicture">看不清换一张
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="logIn" @click="logInClick">登录</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import * as loginApi from '@/request/login';
export default {
    name: 'login',
    data() {
        return {
            url: '', //验证码
            Form: {
                uname: "",
                pwd: "",
                code: "",
            },
            rules: {
                uname: [
                    { required: true, message: '请输入用户名/手机号', trigger: 'blur' },
                ],
                pwd: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                ],
                code: [
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                ],
            },
        };
    },
    mounted() {
        this.VerificationPicture();
    },
    methods: {
        // 获取验证码
        VerificationPicture() {
            loginApi.getYzm().then(res => {
                this.url = window.URL.createObjectURL(new Blob([res], { type: 'image/png' }));
            })
        },
        // 登录
        logInClick() {
            this.$refs.Form.validate((valid) => {
                if (valid) {
                    const params = {
                        uname: this.Form.uname,
                        pwd: this.Form.pwd,
                        code: this.Form.code,
                    }
                    loginApi.login(params).then(res => {
                        sessionStorage.setItem("userinfo", JSON.stringify(res.data))
                        if (res.code === "0") {
                            this.$message({
                                message: `登录${res.message}`,
                                type: 'success'
                            });
                            this.$router.push("/index")
                        } else if (res.code === "0005"){
                            this.Form.code = "";
                            this.$message.error(res.message);
                            this.VerificationPicture()
                        } else if(res.code === "0002") {
                            this.$message.error(res.message);
                        }
                    })
                }
            })
        }
    }
}

</script>
<style lang='scss' scoped>
.headline {
    padding: 20px 40px;
    height: 20px;
    line-height: 20px;
    color: #CD3E21;
}

.main {
    width: 100%;
    height: 885px;
    background: url(../assets/login_page.png);
}

.loginModule {
    width: 440px;
    height: 564px;
    padding: 70px 80px;
    box-sizing: border-box;
    position: absolute;
    top: 220px;
    right: 221px;
    background: #fff;
}

.subheading {
    margin-bottom: 4px;
    color: #85909f;
    font-weight: 400;
    font-size: 16px;
}

.title {
    font-size: 30px;
    font-weight: 600;
    color: #323a44;
    margin-bottom: 20px;
}

.logIn {
    margin-top: 80px;
    width: 280px;
    height: 48px;
    background-color: #CD3E21;
    color: #fff;
}

::v-deep .el-form-item__label {
    color: #DDDDDD;
    font-size: 12px;
    line-height: 20px
}

::v-deep .el-input__inner {
    border: none;
    border-bottom: 1px solid #E6E6E6;
    line-height: 30px;
}
</style>