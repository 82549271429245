<template>
  <div>
    <el-container>
      <el-header>
        <div class="flex justify-content-space-between padding-right-20 padding-left-20">
          <h3 class="color-CD3E21">模型管理系统</h3>
          <div class="flex align-items-center">
            <el-image style="
                width: 40px;
                height: 40px;
                border-radius: 40px;
                margin-right: 5px;
              " :src="url"></el-image>
            <div>{{ userName }}</div>
            <el-button plain class="padding-10-8 margin-left-10" @click="logOut">退出</el-button>
          </div>
        </div>
      </el-header>
      <el-container>
        <el-aside width="200px">
          <el-menu default-active="/model" class="el-menu-vertical-demo" @select="handleSelectMenu">
            <el-menu-item index="/model">
              <i class="el-icon-document"></i>
              <span slot="title">模型数据</span>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import * as loginApi from '@/request/login';

export default {
  name: "home",
  data() {
    return {
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg", // 用户头像
      userName: JSON.parse(sessionStorage.getItem("userinfo")).loginName,
    };
  },

  components: {
  },

  computed: {},

  methods: {
    // 退出登录
    logOut() {
      loginApi.logOut().then(res => {
        if (res.code === "0") {
          sessionStorage.removeItem("userinfo");
          this, this.$router.push("/login");
          this.$message({
            message: "退出登录",
            type: 'success'
          });
        }
      })
    },
    handleSelectMenu(path) {
      this.$router.push(path)
      .catch(()=>{})
    },
  },
};
</script>
<style lang="scss" scoped>
.el-header,
.el-footer {
  background-color: #fff;
  color: #333;
  height: 60px;
  line-height: 60px;
  padding: 0;
  border: 1px solid #e6e6e6;
}

.el-aside {
  width: 160px !important;
  padding: 20px;
  min-height: 885px;
  border-right: 1px solid #e6e6e6;
  background-color: #fff;
}

.el-main {
  background-color: #fff;
}
::v-deep .el-menu {
    border: none;
}

::v-deep .el-menu-item {
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
}
</style>
