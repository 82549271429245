import service from "./axios";

// 获取模型数据
export function MoodelData(params) {
    return service({
        url: `/ModelRecords/page/${params.page}/${params.pageSize}`,
        method: "get",
    });
}

// 模型详情
export function ModelDetails(id) {
    return service({
        url: `/ModelRecords/detail/${id}`,
        method: "get",
    });
}

// 新增
export function ModelAdd(params) {
    return service({
        url: "/ModelRecords/add",
        method: "post",
        data: params
    });
}

// 修改
export function ModelUpdate(params) {
    return service({
        url: `/ModelRecords/update/${params.id}`,
        method: "post",
        data: params,
    });
}

// 删除
export function ModelDelete(id) {
    return service({
        url: `/ModelRecords/delete/${id}`,
        method: "get",
    });
}

// 下载
export function ModelDownload(file) {
    return service({
        url: `/ModelRecords/download/${file}`,
        method: "get",
        responseType: "blob",
    });
}