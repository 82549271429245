import Vue from "vue";
import VueRouter from "vue-router";
import indexView from "../views/indexView.vue";
import login from "@/views/login.vue";
import model from '@/views/model/model.vue';
import modelDetails from '@/views/model/modelDetails.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "/index",
    name: "index",
    redirect: "/model",
    component: indexView,
    children: [
      {
        path: "/model",
        name: "model",
        component: model,
      },
      {
        path: "/model/details",
        name: "details",
        component: modelDetails,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path === "/login") {
    return next();
  };
  const type = sessionStorage.getItem("userinfo");
  if (!type) {
    next("/login");
  } else {
    next();
  }
})

export default router;
